import axios, { AxiosError } from 'axios';
import { LocalStorageKey, getItemString } from '../local_storage/index';
import { APIMethod, mapHTTPStatus, HTTPStatus, ContentType } from './api_enum';
import ResponseData, { ResultCode } from 'data/model/response_data';

/**
 * tạo header cho request api
 */
const createHeader = (type: ContentType) => {
    return {
        Authorization: `Bearer ${getItemString(LocalStorageKey.token)}`,
        'Content-Type': type ?? ContentType.json
    };
};

const createData = (
    type: ContentType,
    method: APIMethod,
    payload?: Record<string, any>
): Record<string, any> | FormData | null => {
    if (payload && method === APIMethod.post) {
        if (type === ContentType.json) {
            return payload;
        } else if (type === ContentType.formData) {
            const formData = new FormData();
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    if (payload[key] instanceof FileList) {
                        for (let i = 0; i < payload[key].length; i++) {
                            formData.append(key, payload[key][i]);
                        }
                    } else {
                        formData.append(key, payload[key]);
                    }
                }
            }

            return formData;
        }
    }
    return null;
};

/**
 * gọi api
 * @param url - url api.
 * @param method - phương thức reques api.
 * @param payload - dữ liệu - tham số của request.
 */
export const callAPI = async (
    url: string,
    method: APIMethod,
    payload?: Record<string, any>,
    type?: ContentType
): Promise<ResponseData> => {
    type = type ?? ContentType.json;
    try {
        const res = await axios({
            method: method,
            headers: createHeader(type),
            url: url,
            params: method === APIMethod.get ? payload : null,
            data: createData(type, method, payload),
            timeout: 600000
        });
        if (mapHTTPStatus(res.status) === HTTPStatus.success) {
            return res.data;
        } else {
            return {
                code:
                    mapHTTPStatus(res.status) === HTTPStatus.authenErro ? ResultCode.authenError : ResultCode.undefine,
                mess: '',
                desc: '',
                data: null
            };
        }
    } catch (ex) {
        const errror = ex as AxiosError;
        return {
            code:
                mapHTTPStatus(errror.request?.status ?? 404) === HTTPStatus.authenErro
                    ? ResultCode.authenError
                    : ResultCode.undefine,
            mess: errror.message,
            desc: '',
            data: null
        };
    }
};

/**
 * call api bằng phương thức get
 * @param url - url api.
 * @param payload - dữ liệu - tham số của request.
 */
export const getAPI = async (url: string, payload?: Record<string, any>, type?: ContentType): Promise<ResponseData> => {
    return await callAPI(url, APIMethod.get, payload, type);
};

/**
 * call api bằng phương thức post
 * @param url - url api.
 * @param payload - dữ liệu - tham số của request.
 */
export const postAPI = async (
    url: string,
    payload?: Record<string, any>,
    type?: ContentType
): Promise<ResponseData> => {
    return await callAPI(url, APIMethod.post, payload, type);
};

/**
 * call api bằng phương thức put
 * @param url - url api.
 * @param payload - dữ liệu - tham số của request.
 */
export const putAPI = async (url: string, payload?: Record<string, any>, type?: ContentType): Promise<ResponseData> => {
    return await callAPI(url, APIMethod.put, payload, type);
};

/**
 * call api bằng phương thức delete
 * @param url - url api.
 * @param payload - dữ liệu - tham số của request.
 */
export const deleteAPI = async (
    url: string,
    payload?: Record<string, any>,
    type?: ContentType
): Promise<ResponseData> => {
    return await callAPI(url, APIMethod.delete, payload, type);
};
