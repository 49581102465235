import RouterPath from './router_path';

/****Layouts*****/
import FullLayout from '../layouts/FullLayout';
import EmptyLayout from '../layouts/EmptyLayout';

/***** Pages ****/
import Authen from 'presentation/pages/authens/Authen';
import Wellcome from 'presentation/pages/wellcome';
import { RouteObject } from 'react-router';
import UserRoleModel from 'data/model/user_role_model';
import { routerRoles } from './navigate_permission';
import ChangeInfoUserPage from 'presentation/pages/authens/ChangeInfoUserPage';
import ChangePasswordUserPage from 'presentation/pages/authens/ChangePasswordUserPage';
import HomePage from 'presentation/pages/HomePage';
import NotFoundPage from 'presentation/pages/NotFoundPage';

/*****danh sách page sau khi đăng nhập ******/
const childrents = (roles: UserRoleModel[]): RouteObject[] => {
    const list = routerRoles(roles).map((item) => {
        return { path: item.path, element: item.element };
    });

    list.push({
        path: RouterPath.home,
        element: <HomePage />
    });
    list.push({
        path: RouterPath.changeInfo,
        element: <ChangeInfoUserPage />
    });
    list.push({
        path: RouterPath.changePass,
        element: <ChangePasswordUserPage />
    });

    return list;
};

/*****Routes******/
const Routes = (roles: UserRoleModel[]): RouteObject[] => {
    return [
        /*****authen******/
        {
            path: RouterPath.wellcome,
            element: <EmptyLayout />,
            children: [
                { path: RouterPath.wellcome, element: <Wellcome /> },
                { path: RouterPath.authen, element: <Authen /> },
                { path: RouterPath.notFount, element: <NotFoundPage /> }
            ]
        },
        /*****theo phân quyền******/
        {
            path: '',
            element: <FullLayout />,
            children: childrents(roles)
        }
    ];
};

export default Routes;
