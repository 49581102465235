/**
 * key quyền cho trang
 */
enum PermissionKey {
    /**
     * quản lý tài khoản admin
     */
    user = 'useradmin',
    /**
     * quản lý tài vùng
     */
    space = 'space',
    /**
     * quản lý tài bài viết
     */
    post = 'post',
    /**
     * quyền cao nhất
     */
    master = 'master'
}

export default PermissionKey;
