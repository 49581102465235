import { useCallback, useEffect, useState } from 'react';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';

type props = {
    onPageSelect: (page: number) => void;
    totalRecord: number;
    currentPage: number;
};

/**phân trang */
const Pagging = ({ totalRecord, currentPage, onPageSelect }: props) => {
    const [curpage, setCurpage] = useState(0);

    const onPageChange = useCallback(
        (event: PaginatorPageChangeEvent) => {
            if (event.page !== currentPage) {
                onPageSelect(event.page);
            } else {
                setCurpage(event.page);
            }
        },
        [currentPage, onPageSelect]
    );

    useEffect(() => {
        setCurpage(currentPage);
    }, [currentPage]);

    if (totalRecord <= 100) {
        return <></>;
    } else {
        return (
            <div className='card'>
                <Paginator first={curpage} rows={100} totalRecords={totalRecord} onPageChange={onPageChange} />
            </div>
        );
    }
};

export default Pagging;
