import LayoutList from 'presentation/component/LayoutList';
import user1 from '../../../assets/images/users/user1.jpg';
import user2 from '../../../assets/images/users/user2.jpg';
import user3 from '../../../assets/images/users/user3.jpg';
import user4 from '../../../assets/images/users/user4.jpg';
import user5 from '../../../assets/images/users/user5.jpg';
const tableData = [
    {
        avatar: user1,
        name: 'Hanna Gover',
        email: 'hgover@gmail.com',
        project: 'Flexy React',
        status: 'pending',
        weeks: '35',
        budget: '95K'
    },
    {
        avatar: user2,
        name: 'Hanna Gover',
        email: 'hgover@gmail.com',
        project: 'Lading pro React',
        status: 'done',
        weeks: '35',
        budget: '95K'
    },
    {
        avatar: user3,
        name: 'Hanna Gover',
        email: 'hgover@gmail.com',
        project: 'Elite React',
        status: 'holt',
        weeks: '35',
        budget: '95K'
    },
    {
        avatar: user4,
        name: 'Hanna Gover',
        email: 'hgover@gmail.com',
        project: 'Flexy React',
        status: 'pending',
        weeks: '35',
        budget: '95K'
    },
    {
        avatar: user5,
        name: 'Hanna Gover',
        email: 'hgover@gmail.com',
        project: 'Ample React',
        status: 'done',
        weeks: '35',
        budget: '95K'
    }
];

/**trang quản lý vùng - menu */
const SpacePage = () => {
    const onSearch = (keyword: string) => {};
    const onCreateNew = () => {};

    return (
        <LayoutList
            title='Vùng - Menu'
            desc='Danh sách vùng - menu'
            loading={false}
            onSearch={onSearch}
            currentPage={1}
            totalRecord={10}
            onCreateNew={onCreateNew}
        >
            <thead>
                <tr>
                    <th>Team Lead</th>
                    <th>Project</th>

                    <th>Status</th>
                    <th>Weeks</th>
                    <th>Budget</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((tdata, index) => (
                    <tr key={index} className='border-top'>
                        <td>
                            <div className='d-flex align-items-center p-2'>
                                <img
                                    src={tdata.avatar}
                                    className='rounded-circle'
                                    alt='avatar'
                                    width='45'
                                    height='45'
                                />
                                <div className='ms-3'>
                                    <h6 className='mb-0'>{tdata.name}</h6>
                                    <span className='text-muted'>{tdata.email}</span>
                                </div>
                            </div>
                        </td>
                        <td>{tdata.project}</td>
                        <td>
                            {tdata.status === 'pending' ? (
                                <span className='p-2 bg-danger rounded-circle d-inline-block ms-3'></span>
                            ) : tdata.status === 'holt' ? (
                                <span className='p-2 bg-warning rounded-circle d-inline-block ms-3'></span>
                            ) : (
                                <span className='p-2 bg-success rounded-circle d-inline-block ms-3'></span>
                            )}
                        </td>
                        <td>{tdata.weeks}</td>
                        <td>{tdata.budget}</td>
                    </tr>
                ))}
            </tbody>
        </LayoutList>
    );
};

export default SpacePage;
