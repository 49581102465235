import _, { replace } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useReducer } from 'react';
import { createOneUser, getDetailUser, getEmptylUser, updateOneUser } from 'services/user_service';
import RouterPath, { navigatePath } from 'presentation/routers/router_path';
import { Form, Row } from 'react-bootstrap';
import { Col, FormGroup, Label } from 'reactstrap';
import AvataForm from 'presentation/component/AvataForm';
import UserRoleModel from 'data/model/user_role_model';
import { userDetailinitialState, userDetailreducer, UserDetailType } from './user_detail_producer';
import InputControl from 'presentation/component/InputControl';
import PasswordControl from 'presentation/component/PasswordControl';
import LayoutForm from 'presentation/component/LayoutForm';

/**trang chi tiết user admin */
const UserDetailPage = () => {
    const { id } = useParams();
    const [state, dispatch] = useReducer(userDetailreducer, userDetailinitialState);
    const navigate = useNavigate();
    const isUpdate = () => !_.isEmpty(state.data.id);

    const onSubmit = async (): Promise<void> => {
        dispatch({ type: UserDetailType.saveData });
        const dataRequest = {
            ...state.data,
            roles: _.filter(state.data.roles, (n) => n.active).map((n) => n.key),
            files: state.files
        };
        const res = isUpdate() ? await updateOneUser(dataRequest) : await createOneUser(dataRequest);
        dispatch({
            type: UserDetailType.saveDone,
            payload: res
                ? {
                      title: 'Thêm mới tài khoản admin',
                      data: res
                  }
                : null
        });
    };

    /** load thông tin user */
    const loadData = useCallback(async () => {
        dispatch({
            type: UserDetailType.loadData
        });
        if (_.isEmpty(id)) {
            const res = await getEmptylUser();
            if (res) {
                dispatch({
                    type: UserDetailType.loadDone,
                    payload: {
                        title: 'Thêm mới tài khoản admin',
                        data: res
                    }
                });
            } else {
                navigate(navigatePath(RouterPath.users));
            }
        } else {
            const res = await getDetailUser({ id: id ?? '' });
            if (res) {
                dispatch({
                    type: UserDetailType.loadDone,
                    payload: {
                        title: 'Cập nhật tài khoản admin',
                        data: res
                    }
                });
            } else {
                navigate(navigatePath(RouterPath.userDetail));
            }
        }
    }, [id, navigate]);

    /** thay đổi thông tin user */
    const formChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, type: UserDetailType) => {
        dispatch({
            type,
            payload: type === UserDetailType.imageChange ? event.target.files : event.target.value
        });
    }, []);

    const rolesChange = useCallback(
        (role: UserRoleModel) => {
            const roles = [...state.data.roles];
            _.each(roles, (item) => {
                if (item.key === role.key) {
                    item.active = !item.active;
                    return;
                }
            });
            dispatch({
                type: UserDetailType.rolesChange,
                payload: roles
            });
        },
        [state.data]
    );

    /**tạo mới user */
    const onCreateNew = useCallback(() => {
        navigate(navigatePath(RouterPath.userDetail), { replace: _.isEmpty(id) });
    }, [id, navigate]);

    /**đến danh sách user */
    const onGotoList = useCallback(() => {
        navigate(navigatePath(RouterPath.users));
    }, [navigate]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <LayoutForm
            title={!isUpdate() ? 'Thêm mới tài khoản admin' : 'Cập nhật tài khoản admin'}
            titleAction={!isUpdate() ? 'Lưu' : 'Cập nhật'}
            loading={state.loading}
            onSubmit={onSubmit}
            onCreate={isUpdate() ? onCreateNew : undefined}
            onGotoList={onGotoList}
        >
            <Row className='justify-content-center'>
                <AvataForm src={state.data.image} onChange={(event) => formChange(event, UserDetailType.imageChange)} />
            </Row>
            <InputControl
                title='Tên đăng nhập'
                value={state.data.userName}
                required
                isUserName
                disabled={isUpdate()}
                onChange={(event) => formChange(event, UserDetailType.userNameChange)}
            />
            <PasswordControl
                title='Mật khẩu'
                value={state.data.password}
                required
                feedback
                hide={isUpdate()}
                onChange={(event) => formChange(event, UserDetailType.passwordChange)}
            />
            <InputControl
                title='Họ và tên'
                value={state.data.fullName}
                required
                onChange={(event) => formChange(event, UserDetailType.fullNameChange)}
            />
            <InputControl
                title='Số điện thoại'
                value={state.data.phone}
                required
                type='tel'
                onChange={(event) => formChange(event, UserDetailType.phoneChange)}
            />
            <InputControl
                title='Email'
                value={state.data.email}
                type='email'
                onChange={(event) => formChange(event, UserDetailType.emailChange)}
            />
            <FormGroup className='mt-4'>
                <Label>Quyền sử dụng</Label>
                <Row className='wrap'>
                    {state.data.roles.map((item) => (
                        <Col key={item.key} lg={6} md={6} xs={12}>
                            <Form.Check
                                type='switch'
                                label={item.title}
                                value={item.key}
                                defaultChecked={item.active}
                                onClick={() => rolesChange(item)}
                            />
                        </Col>
                    ))}
                </Row>
            </FormGroup>
        </LayoutForm>
    );
};

export default UserDetailPage;
