import LogListModel from 'data/model/log_list_model';
import PagingModel from 'data/model/paging_model';

export type LogFormDataType = {
    keword: string;
    loading: boolean;
    listData: LogListModel[];
    totalRecord: number;
    totalPage: number;
    currentPage: number;
};

export type LogActionType = {
    type: LogType;
    payload?: any;
};

export enum LogType {
    loadData = 'LOAD_DATA',
    loadDone = 'LOAD_DONE'
}

export const logInitialState: LogFormDataType = {
    keword: '',
    loading: false,
    listData: [],
    totalRecord: 0,
    totalPage: 0,
    currentPage: 1
};

export const logReducer = (state: LogFormDataType, action: LogActionType) => {
    const resState = { ...state };
    switch (action.type) {
        case LogType.loadDone:
            const payload = action.payload as PagingModel;
            resState.loading = false;
            resState.totalRecord = payload?.totalRecord ?? state.totalRecord;
            resState.totalPage = payload?.totalPage ?? state.totalPage;
            resState.listData = (payload?.data as LogListModel[]) ?? state.listData;
            break;
        case LogType.loadData:
            resState.keword = action.payload.keyword ?? state.keword;
            resState.currentPage = action.payload.currentPage ?? state.currentPage;
            resState.loading = true;
            break;
    }
    return resState;
};
