import { ChangeEventHandler, CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import imageDefault from '../../assets/images/default/no-image.jpg';

type props = {
    src?: string;
    alt?: string;
    className?: string;
    width?: number | string;
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
};

const styleContainer: CSSProperties = {
    width: '10rem',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    border: '1px solid #f2f2f2',
    borderRadius: '1rem',
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    cursor: 'pointer'
};
const styleImage: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
};
const styleIcon: CSSProperties = {
    position: 'absolute',
    bottom: '0.5rem',
    right: '0.5rem'
};

/**
 * vata mặc định của user
 */
const AvataForm = ({ src, alt, className, width, onChange }: props) => {
    const fileInput = useRef<HTMLInputElement | null>(null);
    const [imageUrl, setImageUrl] = useState('');
    /**
     * load hình mặc định khi không load được hình từ src
     */
    const handleError = useCallback((event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.onerror = null; // Ngăn chặn lặp vô hạn nếu hình ảnh mặc định cũng không tải được
        event.currentTarget.src = imageDefault;
    }, []);

    /**
     * mở màn hình chọn file ảnh
     */
    const handleOpenFile = useCallback(() => {
        if (fileInput.current) {
            fileInput.current.click();
        }
    }, []);

    /**
     * khi ảnh được chọn
     */
    const handleFileChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result) {
                        setImageUrl(reader.result.toString());
                    }
                };
                reader.readAsDataURL(file);
                if (onChange) {
                    onChange(event);
                }
            }
        },
        [onChange]
    );

    useEffect(() => {
        setImageUrl(src ?? '');
    }, [src]);

    return (
        <>
            <div style={styleContainer} onClick={handleOpenFile}>
                <img
                    src={imageUrl}
                    style={styleImage}
                    className={className}
                    alt={alt ?? ''}
                    width={width}
                    onError={handleError}
                />
                <i className='bi bi-pencil-square' style={styleIcon}></i>
                <input type='hidden' value={src} />
            </div>
            <input type='file' className='d-none' ref={fileInput} onChange={handleFileChange} />
        </>
    );
};

export default AvataForm;
