import PermissionKey from './permission_key';
import RouterPath from './router_path';
import _ from 'lodash';
import UserRoleModel from '../../data/model/user_role_model';
import UserPage from 'presentation/pages/users/UserPage';
import UserDetailPage from 'presentation/pages/users/UserDetailPage';
import SpacePage from 'presentation/pages/spaces/SpacePage';
import SpaceDetailPage from 'presentation/pages/spaces/SpaceDetailPage';
import PostPage from 'presentation/pages/posts/PostPage';
import PostDetailPage from 'presentation/pages/posts/PostDetailPage';
import LogPage from 'presentation/pages/logs/LogPage';

/**
 * navigate item
 */
type navigateItem = {
    /**
     * tiêu đề
     */
    title: string;
    /**
     * path url
     */
    path: RouterPath;
    /**
     * icon
     */
    icon: string;
    /**
     * quyền của trang
     */
    permission: string;
    /**
     * có phải là trang danh sách hay không
     */
    isList: boolean;
    /**
     * trang theo quyền
     */
    element: React.ReactNode;
};

/**
 * danh sách tất cả navigate
 */
const navigateList: navigateItem[] = [
    {
        title: 'Tài khoản admin',
        path: RouterPath.users,
        icon: 'bi bi-people-fill',
        permission: PermissionKey.user,
        isList: true,
        element: <UserPage />
    },
    {
        title: 'Chi tiết tài khoản admin',
        path: RouterPath.userDetail,
        icon: 'bi bi-people-fill',
        permission: PermissionKey.user,
        isList: false,
        element: <UserDetailPage />
    },
    {
        title: 'Vùng website',
        path: RouterPath.spaces,
        icon: 'bi bi-aspect-ratio',
        permission: PermissionKey.space,
        isList: true,
        element: <SpacePage />
    },
    {
        title: 'Chi tiết vùng website',
        path: RouterPath.spaceDetail,
        icon: 'bi bi-aspect-ratio',
        permission: PermissionKey.space,
        isList: false,
        element: <SpaceDetailPage />
    },
    {
        title: 'Bài viết',
        path: RouterPath.posts,
        icon: 'bi bi-newspaper',
        permission: PermissionKey.post,
        isList: true,
        element: <PostPage />
    },
    {
        title: 'Chi tiết bài viết',
        path: RouterPath.postDetail,
        icon: 'bi bi-newspaper',
        permission: PermissionKey.post,
        isList: false,
        element: <PostDetailPage />
    },
    {
        title: 'Danh sách log',
        path: RouterPath.log,
        icon: 'bi bi-clock-history',
        permission: PermissionKey.master,
        isList: true,
        element: <LogPage />
    }
];

/**
 * map tên page với tên role
 */
const mapRole = (roles: UserRoleModel[]): navigateItem[] => {
    return navigateList.map((item) => {
        const role = _.find(roles, (element) => item.permission.toLowerCase() === element.key.toLowerCase());
        if (role && role.key !== 'master') {
            item.title = role.title;
        }
        return item;
    });
};

/**
 * danh sách router theo phân quyền
 */
export const routerRoles = (roles: UserRoleModel[]): navigateItem[] => {
    roles = _.filter(roles, (item) => item.active);
    return _.filter(
        mapRole(roles),
        (item) => _.findIndex(roles, (element) => item.permission.toLowerCase() === element.key.toLowerCase()) !== -1
    );
};

/**
 * danh sách navigate theo phân quyền
 */
export const navigateRoles = (roles: UserRoleModel[]): navigateItem[] => {
    return _.filter(routerRoles(roles), (item) => item.isList);
};
