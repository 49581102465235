import PagingModel from 'data/model/paging_model';
import { ResultCode } from 'data/model/response_data';
import UserDataRequest, {
    UserChangeStatusRequest,
    UserDetailRequest,
    UserListRequest
} from 'data/requests/userRequest';
import { APIUserManagement, getAPI, postAPI } from 'utils/api_service';
import { toast } from 'react-toastify';
import _ from 'lodash';
import UserModel from 'data/model/user_model';
import { ContentType } from 'utils/api_service/api_enum';
import { isValidEmail, isValidPassword, isValidPhone, isValidUsername } from 'utils/string_ultils';

/**lấy danh sách user admin */
export const getListUser = async (data: UserListRequest): Promise<PagingModel | null> => {
    const res = await getAPI(APIUserManagement.getList, data);
    if (res.code === ResultCode.success) {
        return res.data as PagingModel;
    } else {
        toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
    }
    return null;
};

/**
 * lấy chi tiết user
 */
export const getDetailUser = async (data: UserDetailRequest): Promise<UserModel | null> => {
    let desc = '';
    if (_.isEmpty(data.id)) {
        desc = 'User không tồn tại';
    }

    if (!_.isEmpty(desc)) {
        toast.error(desc);
    } else {
        const res = await getAPI(APIUserManagement.getDetail, data);
        if (res.code === ResultCode.success) {
            return res.data as UserModel;
        } else {
            toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
        }
    }
    return null;
};

/**
 * lấy user empty để tạo mới
 */
export const getEmptylUser = async (): Promise<UserModel | null> => {
    const res = await getAPI(APIUserManagement.getDataEmpty, {});
    if (res.code === ResultCode.success) {
        return res.data as UserModel;
    } else {
        toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
    }

    return null;
};

/**validate dữ liệu trước khi lưu */
const validate = (data: UserDataRequest, isUpDate: boolean): boolean => {
    if (isUpDate && _.isEmpty(data.id)) {
        toast.error('Tài khoản không tồn tại');
        return false;
    } else if (!isValidUsername(data.userName)) {
        toast.error('Vui lòng nhập tên đăng nhập chỉ chứa ký tự, số hoặc dấu _');
        return false;
    } else if (!isValidPassword(data.password) && !isUpDate) {
        toast.error('Vui lòng nhập mật khẩu bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt');
        return false;
    } else if (_.isEmpty(data.fullName)) {
        toast.error('Vui lòng họ và tên');
        return false;
    } else if (!isValidPhone(data.phone)) {
        toast.error('Số điện thoại không hợp lệ');
        return false;
    } else if (!_.isEmpty(data.email) && !isValidEmail(data.email)) {
        toast.error('Email không hợp lệ');
        return false;
    }
    return true;
};

/**
 * tạo mới 1 user
 */
export const createOneUser = async (data: UserDataRequest): Promise<UserModel | null> => {
    if (validate(data, false)) {
        const res = await postAPI(APIUserManagement.createNew, data, ContentType.formData);
        if (res.code === ResultCode.success) {
            toast.success('Tạo tài khoản admin thành công');
            return res.data as UserModel;
        } else {
            toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
        }
    }
    return null;
};

/**
 * cập nhật thông tin 1 user
 */
export const updateOneUser = async (data: UserDataRequest): Promise<UserModel | null> => {
    if (validate(data, true)) {
        const res = await postAPI(APIUserManagement.update, data, ContentType.formData);
        if (res.code === ResultCode.success) {
            toast.success('Cập nhật tài khoản admin thành công');
            return res.data as UserModel;
        } else {
            toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
        }
    }
    return null;
};

/**
 * cập nhật trạng thái 1 user
 * @param confirmed: có cần xác nhận hay không
 */
export const changeStatusOneUser = async (data: UserChangeStatusRequest): Promise<boolean> => {
    let desc = '';
    if (_.isEmpty(data.id)) {
        desc = 'Tài khoản không tồn tại';
    }

    if (!_.isEmpty(desc)) {
        toast.error(desc);
    } else {
        const res = await postAPI(APIUserManagement.changeStatus, data);
        if (res.code === ResultCode.success) {
            toast.success(`${data.status ? 'Khôi phục' : 'Xóa'} tài khoản ${data.name} thành công`);
            return res.data as boolean;
        } else {
            toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
        }
    }
    return false;
};
