import UserModel from './user_model';

/**
 * model authen
 */
export default class AuthenModel {
    /**
     * mã
     */
    token: string;
    /**
     * tên đăng nhập
     */
    userDetail: UserModel;

    constructor() {
        this.token = '';
        this.userDetail = new UserModel();
    }
}