import logo from '../../assets/images/logos/materialpro-primary-color.svg';

const HomePage = () => {
    return (
        <div className='card'>
            <div className='card-body d-flex align-items-center justify-content-center' style={{ height: '85vh' }}>
                <div className='d-flex flex-column align-items-center'>
                    <h4 className='primary'>Wellcome to</h4>
                    <img src={logo} style={{ width: '400px', maxWidth: '80%' }} alt='logo' />
                </div>
            </div>
        </div>
    );
};

export default HomePage;
