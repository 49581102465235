import { NavigateFunction } from 'react-router';

/**
 * kiểu dữ liệu
 */
export type appRouterNavigate = {
    navigator?: NavigateFunction;
};

/**
 * navigate sử dụng chung
 */
const appNavigate: appRouterNavigate = {};

export default appNavigate;
