import _ from 'lodash';

/**
 * định nghĩa các router sử dụng cho toàn ứng dụng
 */
enum RouterPath {
    /**
     * trang mặc định khi mới vào app
     */
    wellcome = '/',
    /**
     * trang chủ
     */
    home = '/home',
    /**
     * trang đăng nhập
     */
    authen = '/authen',
    /**
     * trang lỗi 404
     */
    notFount = '*',
    /**
     * thay đổi mật khẩu
     */
    changePass = '/change-pass',
    /**
     * thay đổi thông tin cá nhân
     */
    changeInfo = '/change-info',
    /**
     * quản lý tài khoản admin
     */
    users = '/user',
    /**
     * chi tiết tài khoản admin
     */
    userDetail = '/user/detail/:id?',
    /**
     * danh sách bài viết
     */
    posts = '/post',
    /**
     * chi tiết bài viết
     */
    postDetail = '/post/detail/:id?',
    /**
     * danh sách vùng
     */
    spaces = '/space',
    /**
     * chi tiết vung
     */
    spaceDetail = '/space/detail/:id?',
    /**
     * danh sách sản phẩm
     */
    product = '/product',
    /**
     * chi tiết sản phẩm
     */
    productDetail = '/product/detail/:id?',
    /**
     * danh sách log
     */
    log = '/log'
}

/**
 * path để điều hướng
 * @param path - path của page
 * @param params - tham số theo cấu hình của router path
 */
export const navigatePath = (path: RouterPath, params?: string[]): string => {
    let _path = path.split(':')[0];
    _path = _path.endsWith('/') ? _path.slice(0, -1) : _path;
    if (params && params.length > 0) {
        _path = `${_path}/${_.join(params, '/')}`;
    }
    return `${_path}`;
};

/**
 * lấy path để xác định page đang active
 * @param path - path của page
 */
export const isPathActive = (path: RouterPath, locationPathname: string): boolean => {
    const _path = path.split('/')[1];
    locationPathname = locationPathname.split('/')[1];
    return _path === locationPathname;
};

export default RouterPath;
