import InputControl from 'presentation/component/InputControl';
import PasswordControl from 'presentation/component/PasswordControl';
import Loader from 'presentation/layouts/Loader';
import { authenAction } from 'presentation/redux/slices/authen_slice';
import { RootState, useAppAppDispatch, useAppSelector } from 'presentation/redux/store';
import React, { useCallback, useState } from 'react';
import { Form, Container } from 'react-bootstrap';

/**
 * đăng nhập - authen
 */
const Authen = () => {
    const [userName, setUserName] = useState('');
    const [password, setUserPassword] = useState('');
    const dispatch = useAppAppDispatch();
    const loading = useAppSelector((state: RootState) => state.authen.loading);

    /**
     * sự kiện submit form - nhấn nút đăng nhập
     */
    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            dispatch(authenAction.authen({ userName, password }));
        },
        [dispatch, password, userName]
    );

    return (
        <Container fluid className='authen-background'>
            {loading && <Loader />}
            <Form className='authen-form' onSubmit={handleSubmit}>
                <h3 className='authen-header text-center mb-3'>Đăng nhập</h3>
                <InputControl
                    title='Tên đăng nhập'
                    value={userName}
                    required
                    isUserName
                    onChange={(event) => setUserName(event.target.value)}
                />
                <PasswordControl
                    title='Mật khẩu'
                    value={password}
                    required
                    notValidate
                    onChange={(event) => setUserPassword(event.target.value)}
                />
                <div className='d-grid input-field-mt'>
                    <button type='submit' className='btn btn-primary'>
                        Đăng nhập
                    </button>
                </div>
            </Form>
        </Container>
    );
};

export default Authen;
