import Loader from 'presentation/layouts/Loader';
import { ConfirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardSubtitle, Table, Row, Col, InputGroup } from 'reactstrap';
import { FiPlus, FiRefreshCcw } from 'react-icons/fi';
import Pagging from './Pagging';

type propsType = {
    /** tiêu đề*/
    title: string;
    /** mô tả*/
    desc?: string;
    /** ẩn hiện spinner*/
    loading: boolean;
    /** thẻ con*/
    children: React.ReactNode;
    /**tổng số dong */
    totalRecord: number;
    /**trang hiện tại */
    currentPage: number;
    /** sự kiện load dữ liệu*/
    onSearch: (keyWord: string, page: number) => void;
    /**sự kiện nhấn nút thêm mới */
    onCreateNew?: () => void;
};

/**lay out của danh sách */
const LayoutList = ({ title, desc, loading, children, onSearch, onCreateNew, totalRecord, currentPage }: propsType) => {
    const [keyword, setKeyword] = useState('');

    const keywordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    }, []);

    const onClickSearch = useCallback(() => {
        onSearch(keyword, 1);
    }, [keyword, onSearch]);

    const onClickCreateNew = useCallback(() => {
        if (onCreateNew) {
            onCreateNew();
        }
    }, [onCreateNew]);

    const onEnterInput = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === 13) {
                onSearch(keyword, 1);
            }
        },
        [keyword, onSearch]
    );

    const onPageSelect = useCallback(
        (page: number) => {
            onSearch(keyword, page);
        },
        [keyword, onSearch]
    );

    return (
        <>
            <Card>
                <ConfirmDialog />
                {loading && <Loader />}
                <CardTitle className='border-bottom p-3 mb-0'>
                    <Row className='wrap align-items-center'>
                        <Col xl={4} md={5} xs={12}>
                            <h6>{title}</h6>
                            <CardSubtitle className='mb-2 text-muted' tag='h6'>
                                {desc}
                            </CardSubtitle>
                        </Col>
                        <Col xl={8} md={7} xs={12} className='align-items-center'>
                            <Row>
                                <div className='d-flex flex-1'></div>
                                <Col xl={5} md={12} xs={12}>
                                    <InputGroup>
                                        <Form.Control
                                            placeholder='Nhập từ khóa cần tìm'
                                            onChange={keywordChange}
                                            onKeyUp={onEnterInput}
                                        />
                                        <Button variant='primary' onClick={onClickSearch}>
                                            <i className='bi bi-search'></i>
                                        </Button>
                                    </InputGroup>
                                </Col>
                                <Col className='d-flex mt-lg-0 mt-3 justify-content-end'>
                                    <Button
                                        variant='primary'
                                        className='btn-form-list btn-icon'
                                        onClick={onClickCreateNew}
                                    >
                                        <FiPlus size={15} />
                                        <label>Thêm mới</label>
                                    </Button>
                                    <Button
                                        variant='info'
                                        className='ml-4 btn-form-list btn-icon'
                                        onClick={onClickSearch}
                                    >
                                        <FiRefreshCcw size={15} />
                                        <label>Tải lại</label>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardTitle>
                <CardBody className='min-height-table-list'>
                    <Table className='no-wrap mt-3 align-middle' responsive borderless>
                        {children}
                    </Table>
                </CardBody>
            </Card>
            <Pagging currentPage={currentPage} totalRecord={totalRecord} onPageSelect={onPageSelect} />
        </>
    );
};

export default LayoutList;
