/**
 * Kết quả trả về sau khi gọi api
 */
export type APIRes = {
    code: HTTPStatus;
    mess: string;
    data: string;
};

/**
 * trạng thái của kết quả trả về sau khi gọi api
 */
export enum HTTPStatus {
    waitting = 100,
    success = 200,
    redirects = 300,
    clientErrors = 400,
    authenErro = 401,
    serverErrors = 500,
    undefine = 0
}

/**
 * phương thức request của api
 */
export enum APIMethod {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    head = 'head',
    options = 'options',
    patch = 'patch',
    purge = 'purge',
    link = 'link',
    unlink = 'unlink'
}

/**
 * loại dữ liệu request
 */
export enum ContentType {
    xwwwFormUrlencoded = 'application/x-www-form-urlencoded',
    formData = 'multipart/form-data',
    json = 'application/json',
    text = 'text/plain',
    xml = 'application/xml',
    stream = 'application/octet-stream'
}

/**
 * chuyển đổi httpStatusCode thành HTTPStatus
 */
export const mapHTTPStatus = (statusCode: number): HTTPStatus => {
    if (statusCode === 401) {
        return HTTPStatus.authenErro;
    } else if (statusCode >= 100 && statusCode <= 199) {
        return HTTPStatus.waitting;
    } else if (statusCode >= 200 && statusCode <= 299) {
        return HTTPStatus.success;
    } else if (statusCode >= 300 && statusCode <= 399) {
        return HTTPStatus.redirects;
    } else if (statusCode >= 400 && statusCode <= 499) {
        return HTTPStatus.clientErrors;
    } else if (statusCode >= 500 && statusCode <= 599) {
        return HTTPStatus.serverErrors;
    } else {
        return HTTPStatus.undefine;
    }
};
