import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import appNavigate from 'presentation/routers/app_navigate';
import { useEffect } from 'react';
import { isAuthened } from 'services/authen_service';
import { ToastContainer } from 'react-toastify';
import './assets/scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { useSelector } from 'react-redux';
import { RootState, useAppAppDispatch } from 'presentation/redux/store';
import { authenAction } from 'presentation/redux/slices/authen_slice';
import RouterPath from 'presentation/routers/router_path';
import Routes from 'presentation/routers/routers';
import { PrimeReactProvider } from 'primereact/api';

const App = () => {
    appNavigate.navigator = useNavigate();
    const location = useLocation();
    const userInfo = useSelector((state: RootState) => state.authen.userInfo);
    const dispatch = useAppAppDispatch();

    useEffect(() => {
        const authened = isAuthened();
        if (location.pathname !== RouterPath.authen && location.pathname !== RouterPath.wellcome) {
            if (!authened) {
                appNavigate.navigator!(RouterPath.authen);
            } else if (!userInfo) {
                dispatch(authenAction.reloadAuthen());
            }
        } else {
            if (authened) {
                appNavigate.navigator!(RouterPath.home);
            } else {
                if (location.pathname !== RouterPath.authen) {
                    appNavigate.navigator!(RouterPath.authen);
                }
            }
        }
    });

    return (
        <PrimeReactProvider value={{ unstyled: false }}>
            <div className='dark'>
                {useRoutes(Routes(userInfo?.roles ?? []))}
                <ToastContainer />
            </div>
        </PrimeReactProvider>
    );
};

export default App;
