import LayoutList from 'presentation/component/LayoutList';
import { useCallback, useEffect, useReducer } from 'react';
import AvataUser from 'presentation/component/AvataUser';
import { changeStatusOneUser, getListUser } from 'services/user_service';
import { useNavigate } from 'react-router';
import RouterPath, { navigatePath } from 'presentation/routers/router_path';
import { userInitialState, userReducer, UserType } from './user_producer';
import TableRow from 'presentation/component/TableRow';

/**trang quản lý user admin */
const UserPage = () => {
    const [state, dispatch] = useReducer(userReducer, userInitialState);
    const navigate = useNavigate();

    /**load danh sách user */
    const loadData = useCallback(async (keyword: string, page: number) => {
        dispatch({
            type: UserType.loadData,
            payload: {
                keyword: keyword,
                currentPage: page
            }
        });
        const res = await getListUser({ keyword: keyword, currentPage: page });
        dispatch({
            type: UserType.loadDone,
            payload: res
        });
    }, []);

    /**tạo mới user */
    const onCreateNew = useCallback(() => {
        navigate(navigatePath(RouterPath.userDetail));
    }, [navigate]);

    /**sự kiện lấy dữ liệu */
    const onSearch = useCallback(
        (keyword: string, page: number) => {
            loadData(keyword, page);
        },
        [loadData]
    );

    /**sự kiện cập nhật trạng thái */
    const onUpdateStatus = useCallback(
        async (id: string, title: string, status: boolean) => {
            dispatch({ type: UserType.changeStatus });
            const res = await changeStatusOneUser({ id: id, status: !status, name: title });
            if (res) {
                loadData(state.keword, state.currentPage);
            } else {
                dispatch({ type: UserType.changeStatusError });
            }
        },
        [loadData, state.currentPage, state.keword]
    );

    useEffect(() => {
        loadData('', 1);
    }, [loadData]);

    return (
        <LayoutList
            title='User admin'
            desc='Danh sách các tài khoản admin'
            loading={state.loading}
            onSearch={onSearch}
            totalRecord={state.totalRecord}
            currentPage={state.currentPage}
            onCreateNew={onCreateNew}
        >
            <thead>
                <tr>
                    <th>Tên đăng nhập</th>
                    <th>Số điện thoại</th>
                    <th>Email</th>
                    <th>Trạng thái</th>
                </tr>
            </thead>
            <tbody>
                {state.listData.map((item) => (
                    <TableRow
                        key={item.id}
                        id={item.id}
                        title={item.userName}
                        navEdit={RouterPath.userDetail}
                        status={item.status}
                        onUpdateStatus={onUpdateStatus}
                    >
                        <td>
                            <div className='d-flex align-items-center p-2'>
                                <AvataUser src={item.image} className='rounded-circle' alt='avatar' size='45' />
                                <div className='ms-3'>
                                    <h6 className='mb-0'>{item.userName}</h6>
                                    <span className='text-muted'>{item.fullName}</span>
                                </div>
                            </div>
                        </td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>
                            {item.status ? (
                                <span className='p-2 bg-success rounded-circle d-inline-block ms-3'></span>
                            ) : (
                                <span className='p-2 bg-danger rounded-circle d-inline-block ms-3'></span>
                            )}
                        </td>
                    </TableRow>
                ))}
            </tbody>
        </LayoutList>
    );
};

export default UserPage;
