import { Button, Nav, NavItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import probg from '../../assets/images/bg/download.jpg';
import { RootState, useAppSelector } from 'presentation/redux/store';
import AvataUser from 'presentation/component/AvataUser';
import { navigateRoles } from 'presentation/routers/navigate_permission';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isPathActive } from 'presentation/routers/router_path';

const Sidebar = () => {
    const userInfo = useAppSelector((state: RootState) => state.authen.userInfo);
    const showMobilemenu = () => {
        (document.getElementById('sidebarArea') as HTMLElement).classList.toggle('showSidebar');
    };
    let location = useLocation();

    return (
        <div>
            <div className='d-flex align-items-center'></div>
            <div className='profilebg' style={{ background: `url(${probg}) no-repeat`, backgroundSize: 'cover' }}>
                <div className='p-3 d-flex'>
                    <AvataUser src={userInfo?.image} alt='user' size='50' className='rounded-circle' />
                    <Button color='white' className='ms-auto text-white d-lg-none' onClick={() => showMobilemenu()}>
                        <i className='bi bi-x'></i>
                    </Button>
                </div>
                <div className='bg-dark text-white p-2 opacity-75'>{userInfo?.fullName}</div>
            </div>
            <div className='p-3 mt-2'>
                <Nav vertical className='sidebarNav'>
                    {navigateRoles(userInfo?.roles ?? []).map((navi) => (
                        <NavItem key={navi.path} className='sidenav-bg'>
                            <Link
                                to={navi.path}
                                className={
                                    isPathActive(navi.path, location.pathname)
                                        ? 'active nav-link py-3'
                                        : 'nav-link text-secondary py-3'
                                }
                            >
                                <i className={navi.icon}></i>
                                <OverlayTrigger overlay={<Tooltip id={navi.permission}>{navi.title}</Tooltip>}>
                                    <span className='ms-3'>{navi.title}</span>
                                </OverlayTrigger>
                            </Link>
                        </NavItem>
                    ))}
                </Nav>
            </div>
        </div>
    );
};

export default Sidebar;
