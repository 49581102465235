/**
 * Các key lưu trữ local storage
 */
enum LocalStorageKey {
    /**
     * token authen
     */
    token = 'token',
    /**
     * thông tin user đăng nhập
     */
    userInfo = 'user-info'
}

export default LocalStorageKey;
