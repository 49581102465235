import LayoutList from 'presentation/component/LayoutList';
import { useCallback, useEffect, useReducer } from 'react';
import RouterPath from 'presentation/routers/router_path';
import TableRow from 'presentation/component/TableRow';
import { logInitialState, logReducer, LogType } from './log_producer';
import { getListLog } from 'services/log_service';

/**trang quản lý log */
const LogPage = () => {
    const [state, dispatch] = useReducer(logReducer, logInitialState);

    /**load danh sách user */
    const loadData = useCallback(async (keyword: string, page: number) => {
        dispatch({
            type: LogType.loadData,
            payload: {
                keyword: keyword,
                currentPage: page
            }
        });
        const res = await getListLog({ keyword: keyword, currentPage: page });
        dispatch({
            type: LogType.loadDone,
            payload: res
        });
    }, []);

    /**sự kiện lấy dữ liệu */
    const onSearch = useCallback(
        (keyword: string, page: number) => {
            loadData(keyword, page);
        },
        [loadData]
    );

    useEffect(() => {
        loadData('', 1);
    }, [loadData]);

    return (
        <LayoutList
            title='Danh sách log'
            desc='Log theo dõi hệ thống và lỗi'
            loading={state.loading}
            currentPage={state.currentPage}
            totalRecord={state.totalRecord}
            onSearch={onSearch}
        >
            <thead>
                <tr>
                    <th>Thời gian</th>
                    <th>Loại</th>
                    <th>Nơi log</th>
                    <th>Nội dung</th>
                    <th>Lỗi</th>
                </tr>
            </thead>
            <tbody>
                {state.listData.map((item) => (
                    <TableRow
                        key={item.sDate}
                        id={item.sDate}
                        title={item.logger}
                        navEdit={RouterPath.userDetail}
                        notAllowEdit
                    >
                        <td>{item.sDate}</td>
                        <td>{item.level}</td>
                        <td>{item.logger}</td>
                        <td>
                            <span className='cell-maxline'>{item.message}</span>
                        </td>
                        <td>
                            <span className='cell-maxline'>{item.exception}</span>
                        </td>
                    </TableRow>
                ))}
            </tbody>
        </LayoutList>
    );
};

export default LogPage;
