/**
 * mã lỗi trả về từ api
 */
export enum ResultCode {
    /**
     * thành công
     */
    success = 0,
    /**
     * tiêu đề tồn tại
     */
    existTitle = 1,
    /**
     * không tồn tại
     */
    notExist = 2,
    /**
     * đã tồn tại
     */
    exist = 3,
    /**
     * lỗi không có quyền truy cập - chưa đăng nhập
     */
    authenError = 4,
    /**
     * lỗi không có quyền truy cập
     */
    roleError = 5,
    /**
     * dữ liệu bằng null hoặc có field không hợp lệ
     */
    dataError = 6,
    /**
     * cấp cha ở trạng thái không sử dụng
     */
    parentNotAvaiable = 7,
    /**
     * upload hình ảnh không thành công
     */
    uploadError = 8,
    /**
     * mật khẩu cũ không đúng
     */
    passwoldOldErro = 9,
    /**
     * Lỗi không xác định
     */
    undefine = 10,
    /**
     * Đăng nhập lỗi
     */
    authenFalse = 11,
    /**
     * Cập nhật dữ liệu bị lỗi
     */
    updateDataFalse = 12,
    /**
     * token bị hết hạn
     */
    tokenexpired = 13
}

/**
 * dữ liệu trả về từ api
 */
export default class ResponseData {
    code: ResultCode;
    mess: string;
    desc: string;
    data: any;

    constructor() {
        this.code = ResultCode.undefine;
        this.mess = '';
        this.desc = '';
        this.data = null;
    }
}
