import _ from 'lodash';
import LayoutForm from 'presentation/component/LayoutForm';
import { useParams } from 'react-router-dom';

/**trang chi tiết vùng - menu */
const SpaceDetailPage = () => {
    const { id } = useParams();

    const onSubmit = (): void => {};

    return (
        <LayoutForm
            title={_.isEmpty(id) ? 'Thêm mới vùng - menu' : 'Cập nhật vùng - menu'}
            titleAction='Lưu'
            loading={false}
            onSubmit={onSubmit}
        >
            <div></div>
        </LayoutForm>
    );
};

export default SpaceDetailPage;
