import { Outlet } from 'react-router-dom';

/**
 * layout trống cho trang đăng nhập và một số trang cần thiết
 */
const EmptyLayout = () => {
    return <Outlet />;
};

export default EmptyLayout;
