/**kiểm tra email hợp lệ */
export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

/**kiểm tra số điện thoại hợp lệ */
export const isValidPhone = (phone: string): boolean => {
    // kiểm tra tính hợp lệ của số điện thoại bao gồm mã vùng quốc tế trong
    const phoneRegex = /^(\+?\d{1,3})?[-. (]*\d{3}[-. )]*\d{3}[-. ]*\d{4}$/;
    return phoneRegex.test(phone);
};

/**kiểm tra password hợp lệ */
export const isValidPassword = (password: string): boolean => {
    // username phải chứa ít nhất 6 ký tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
};

/**kiểm tra userName hợp lệ */
export const isValidUsername = (username: string): boolean => {
    // username chỉ chứa các ký tự chữ cái, số hoặc dấu gạch dưới (_)
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
    return usernameRegex.test(username);
};