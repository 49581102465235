import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, ThunkAction } from '@reduxjs/toolkit';
import store from './store';

// lấy rootstate từ store
export type RootState = ReturnType<typeof store.getState>;
// lấy dispatch từ store
export type AppDispatch = typeof store.dispatch;
// type của midleware thunk
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
/**
 * thay vì sử dụng useDispatch thì hãy sử dụng useAppAppDispatch để không phải khai báo lại kiểu dữ liệu
 */
export const useAppAppDispatch = () => useDispatch<AppDispatch>();
/**
 * thay vì sử dụng useSelector thì hãy sử dụng useAppSelector để không phải khai báo lại kiểu dữ liệu
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
