import { useParams } from 'react-router-dom';
import _ from 'lodash';
import LayoutForm from 'presentation/component/LayoutForm';

/**trang chi tiết bài viết */
const PostDetailPage = () => {
    const { id } = useParams();

    const onSubmit = (): void => {};

    return (
        <LayoutForm
            title={_.isEmpty(id) ? 'Thêm mới bài viết' : 'Cập nhật bài viết'}
            titleAction='Lưu'
            loading={false}
            onSubmit={onSubmit}
        >
            <div></div>
        </LayoutForm>
    );
};

export default PostDetailPage;
