import { Container } from 'react-bootstrap';
import logoDark from '../../assets/images/logos/materialpro.svg';

/**
 * trang wellome khi người dùng mới vào app
 */
const Wellcome = () => {
    return (
        <Container fluid className='authen-background'>
            <img src={logoDark} alt='logo' />
        </Container>
    );
};

export default Wellcome;
