import { changePasswordRequest } from 'data/requests/authenRequest';
import LayoutForm from 'presentation/component/LayoutForm';
import PasswordControl from 'presentation/component/PasswordControl';
import { authenAction } from 'presentation/redux/slices/authen_slice';
import { RootState, useAppAppDispatch, useAppSelector } from 'presentation/redux/store';
import React, { useCallback, useState } from 'react';

/**
 * loại thay đổi của form
 */
enum typeChange {
    passwordOld,
    passwordNew,
    passwordConfirm
}

/**thay đổi password user đăng nhập */
const ChangePasswordUserPage = () => {
    const userInfo = useAppSelector((state: RootState) => state.authen.userInfo);
    const loading = useAppSelector((state: RootState) => state.authen.loading);
    const dispatch = useAppAppDispatch();
    const [formData, setFormData] = useState({
        userId: userInfo?.id ?? '',
        passwordOld: '',
        passwordNew: '',
        passwordConfirm: ''
    } as changePasswordRequest);

    /**
     * xử lý thay đổi của form
     */
    const formChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, type: typeChange) => {
            const value = event.target.value;
            switch (type) {
                case typeChange.passwordOld:
                    setFormData({
                        ...formData,
                        passwordOld: value
                    });
                    break;
                case typeChange.passwordNew:
                    setFormData({
                        ...formData,
                        passwordNew: value
                    });
                    break;
                case typeChange.passwordConfirm:
                    setFormData({
                        ...formData,
                        passwordConfirm: value
                    });
                    break;
            }
        },
        [formData]
    );

    /**
     * sự kiện submit form
     */
    const handleSubmit = useCallback(() => {
        dispatch(authenAction.changePassword(formData));
    }, [dispatch, formData]);

    return (
        <LayoutForm title='Đổi mật khẩu' titleAction='Xác nhận' loading={loading} onSubmit={handleSubmit}>
            <PasswordControl
                title='Mật khẩu hiện tại'
                value={formData.passwordOld}
                required
                notValidate
                onChange={(event) => formChange(event, typeChange.passwordOld)}
            />
            <PasswordControl
                title='Mật khẩu mới'
                value={formData.passwordNew}
                required
                feedback
                onChange={(event) => formChange(event, typeChange.passwordNew)}
            />
            <PasswordControl
                title='Xác nhận mật khẩu'
                value={formData.passwordConfirm}
                required
                notValidate
                onChange={(event) => formChange(event, typeChange.passwordConfirm)}
            />
        </LayoutForm>
    );
};

export default ChangePasswordUserPage;
