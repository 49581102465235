import { call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { authenAction } from '../slices/authen_slice';
import appNavigate from '../../routers/app_navigate';
import {
    isAuthened,
    logout,
    getAuthenOld,
    getAuthen,
    changeUserInfo,
    changePassword
} from '../../../services/authen_service';
import { toast } from 'react-toastify';
import RouterPath from 'presentation/routers/router_path';
import { authenRequest, changePasswordRequest, changeUserInfoRequest } from 'data/requests/authenRequest';
import ResponseData, { ResultCode } from 'data/model/response_data';
import _ from 'lodash';
import { Task } from 'redux-saga';

/**
 * luồng đăng nhập
 */
function* handleAuthen(payload: authenRequest) {
    const res: ResponseData = yield call(async () => {
        return await getAuthen(payload);
    });
    if (res.code === ResultCode.success) {
        yield put(authenAction.authenSuccess(res.data));
        yield fork(watchUserActions);
        appNavigate.navigator!(RouterPath.home);
    } else {
        yield put(authenAction.authenFailed());
        toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
    }
}

/**
 * luồng đã đăng nhập nhưng reload trang
 */
function* handleReloadAuthen() {
    const authenInfo = getAuthenOld();
    if (authenInfo) {
        yield put(authenAction.authenSuccess(authenInfo));
        yield fork(watchUserActions);
    } else {
        yield fork(handleLogout);
    }
}

/**
 * luồng đã đăng nhập sau đó đổi mật khẩu
 */
function* handleChangeUserInfo(action: PayloadAction<changeUserInfoRequest>) {
    const res: ResponseData = yield call(async () => {
        return await changeUserInfo(action.payload);
    });
    if (res.code === ResultCode.success) {
        yield put(authenAction.changeUserInfoSuccess(res.data));
        toast.success('Cập nhật thông tin tài khoản thành công');
    } else {
        yield put(authenAction.changeUserInfoFailed());
        toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
    }
}

/**
 * luồng đã đăng nhập sau đó cập nhật thông tin
 */
function* handleChangePassword(action: PayloadAction<changePasswordRequest>) {
    const res: ResponseData = yield call(async () => {
        return await changePassword(action.payload);
    });
    if (res.code === ResultCode.success) {
        yield put(authenAction.changePasswordSuccess(res.data));
        toast.success('Thay đổi mật khẩu thành công');
    } else {
        yield put(authenAction.changePasswordFailed());
        toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
    }
}

// xử lý đăng xuất
function* handleLogout() {
    logout();
    appNavigate.navigator!(RouterPath.authen);
    yield put(authenAction.logout());
}

// lắng nghe và xử lý các hành động người dùng
function* watchUserActions() {
    const changeUserInfoTask: Task<any> = yield takeEvery(authenAction.changeUserInfo.type, handleChangeUserInfo);
    const changePasswordTask: Task<any> = yield takeEvery(authenAction.changePassword.type, handleChangePassword);
    try {
        // Chờ tới khi bị hủy bỏ
        yield take(authenAction.logout.type);
        yield call(handleLogout);
    } finally {
        yield cancel(changeUserInfoTask);
        yield cancel(changePasswordTask);
    }
}

// Saga con để xử lý đăng nhập và các hành động khác khi người dùng đã xác thực
function* handleAuthenFlow(payload: authenRequest) {
    yield call(handleAuthen, payload);
}

/**
 * theo dõi đăng nhập đăng xuất
 */
function* watchAuthenFlow() {
    while (true) {
        const authened = isAuthened();
        if (!authened) {
            const actionAuthen: PayloadAction<authenRequest> = yield take(authenAction.authen.type);
            yield call(handleAuthenFlow, actionAuthen.payload);
        } else {
            yield take(authenAction.reloadAuthen.type);
            yield call(handleReloadAuthen);
        }
    }
}

/**
 * khởi tạo cho middleware saga cho authen
 */
export default function* authenSaga() {
    yield fork(watchAuthenFlow);
}
