import { changeUserInfoRequest } from 'data/requests/authenRequest';
import AvataForm from 'presentation/component/AvataForm';
import InputControl from 'presentation/component/InputControl';
import LayoutForm from 'presentation/component/LayoutForm';
import { authenAction } from 'presentation/redux/slices/authen_slice';
import { RootState, useAppAppDispatch, useAppSelector } from 'presentation/redux/store';
import { useCallback, useState } from 'react';
import { Row } from 'reactstrap';

/**
 * loại thay đổi của form
 */
enum typeChange {
    fullName,
    phone,
    email,
    image
}

/**thay đổi thông tin user đăng nhập */
const ChangeInfoUserPage = () => {
    const userInfo = useAppSelector((state: RootState) => state.authen.userInfo);
    const loading = useAppSelector((state: RootState) => state.authen.loading);
    const dispatch = useAppAppDispatch();
    const [formData, setFormData] = useState({
        userId: userInfo?.id ?? '',
        fullName: userInfo?.fullName ?? '',
        phone: userInfo?.phone ?? '',
        email: userInfo?.email ?? '',
        image: userInfo?.image ?? '',
        files: null
    } as changeUserInfoRequest);

    /**
     * xử lý thay đổi của form
     */
    const formChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, type: typeChange) => {
            switch (type) {
                case typeChange.fullName:
                    setFormData({
                        ...formData,
                        fullName: event.target.value
                    });
                    break;
                case typeChange.phone:
                    setFormData({
                        ...formData,
                        phone: event.target.value
                    });
                    break;
                case typeChange.email:
                    setFormData({
                        ...formData,
                        email: event.target.value
                    });
                    break;
                case typeChange.image:
                    setFormData({
                        ...formData,
                        files: event.target.files
                    });
                    break;
            }
        },
        [formData]
    );

    /**
     * sự kiện submit form
     */
    const handleSubmit = useCallback(() => {
        dispatch(authenAction.changeUserInfo(formData));
    }, [dispatch, formData]);

    return (
        <LayoutForm title='Thông tin tài khoản' titleAction='Xác nhận' loading={loading} onSubmit={handleSubmit}>
            <Row className='justify-content-center'>
                <AvataForm src={formData.image} onChange={(event) => formChange(event, typeChange.image)} />
            </Row>
            <InputControl
                title='Họ và tên'
                value={formData.fullName}
                required
                onChange={(event) => formChange(event, typeChange.fullName)}
            />
            <InputControl
                title='Số điện thoại'
                value={formData.phone}
                required
                type='tel'
                onChange={(event) => formChange(event, typeChange.phone)}
            />
            <InputControl
                title='Email'
                value={formData.email}
                type='email'
                onChange={(event) => formChange(event, typeChange.email)}
            />
        </LayoutForm>
    );
};

export default ChangeInfoUserPage;
