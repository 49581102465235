import PagingModel from 'data/model/paging_model';
import UserListModel from 'data/model/user_list_model';

export type UserFormDataType = {
    keword: string;
    loading: boolean;
    listData: UserListModel[];
    totalRecord: number;
    totalPage: number;
    currentPage: number;
};

export type UserActionType = {
    type: UserType;
    payload?: any;
};

export enum UserType {
    loadData = 'LOAD_DATA',
    loadDone = 'LOAD_DONE',
    createNew = 'CREATE_NEW',
    changeStatus = 'CHANGE_STATUS',
    changeStatusError = 'CHANGE_STATUS_ERROR'
}

export const userInitialState: UserFormDataType = {
    keword: '',
    loading: false,
    listData: [],
    totalRecord: 0,
    totalPage: 0,
    currentPage: 1
};

export const userReducer = (state: UserFormDataType, action: UserActionType) => {
    const resState = { ...state };
    switch (action.type) {
        case UserType.loadDone:
            const payload = action.payload as PagingModel;
            resState.loading = false;
            resState.totalRecord = payload?.totalRecord ?? state.totalRecord;
            resState.totalPage = payload?.totalPage ?? state.totalPage;
            resState.listData = (payload?.data as UserListModel[]) ?? state.listData;
            break;
        case UserType.loadData:
            resState.keword = action.payload.keyword ?? state.keword;
            resState.currentPage = action.payload.currentPage ?? state.currentPage;
            resState.loading = true;
            break;
        case UserType.createNew:
            break;
        case UserType.changeStatus:
            resState.loading = true;
            break;
        case UserType.changeStatusError:
            resState.loading = false;
            break;
    }
    return resState;
};
