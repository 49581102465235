import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';
import _ from 'lodash';
import { isValidEmail, isValidPhone, isValidUsername } from 'utils/string_ultils';

type props = {
    /**tiêu đề - label */
    title: string;
    /**giá trị */
    value: string;
    /** có phải ô nhập username hay không */
    isUserName?: boolean;
    /**có bắt buộc nhập hay không */
    required?: boolean;
    /** loại input */
    type?: HTMLInputTypeAttribute;
    /**sự kiện khi giá trị thay đổi */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** ẩn hay hiện input */
    hide?: boolean;
    /**có vô hiệu ô nhập - không cho nhập hay không */
    disabled?: boolean;
};

/**input text */
const InputControl = ({ title, value, required, type, onChange, hide, isUserName, disabled }: props) => {
    if (!hide) {
        const invalid = (): boolean => {
            if (required && _.isEmpty(value)) {
                return true;
            } else {
                if (type === 'email' && !_.isEmpty(value)) {
                    return !isValidEmail(value);
                } else if (type === 'tel' && !_.isEmpty(value)) {
                    return !isValidPhone(value);
                } else if (type === 'text' && isUserName && !_.isEmpty(value)) {
                    return !isValidUsername(value);
                }
            }
            return false;
        };

        return (
            <FloatLabel>
                <InputText
                    value={value}
                    type={type}
                    className='form-control input-field-mt'
                    onChange={onChange}
                    required={required}
                    invalid={invalid()}
                    disabled={disabled}
                />
                <label>{`${title} ${required ? '*' : ''}`}</label>
            </FloatLabel>
        );
    }
    return <></>;
};

export default InputControl;
