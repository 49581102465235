import RouterPath, { navigatePath } from 'presentation/routers/router_path';
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

type typeProps = {
    /** thẻ con*/
    children: React.ReactNode;
    /**mã dữ liệu */
    id: string;
    /** tên - tiêu đề */
    title: string;
    /**điều hướng khi edit */
    navEdit: RouterPath;
    /** trạng thái */
    status?: boolean;
    /**không cho chỉnh sửa */
    notAllowEdit?: boolean;
    /**sự kiện update status */
    onUpdateStatus?: (id: string, title: string, status: boolean) => void;
};

/** dòng của bảng */
const TableRow = ({ children, id, title, navEdit, status, notAllowEdit, onUpdateStatus }: typeProps) => {
    const clickUpdateStatus = useCallback(() => {
        confirmDialog({
            message: `Bạn có muốn ${status ? 'xóa' : 'khôi phục'} "${title}: không?`,
            header: 'Xác nhận',
            defaultFocus: 'reject',
            rejectLabel: 'Có',
            acceptLabel: 'Không',
            acceptClassName: 'btn light',
            rejectClassName: status ? 'btn btn-danger' : 'btn btn-warning',
            reject: () => {
                if (onUpdateStatus) {
                    onUpdateStatus(id, title, status ?? false);
                }
            }
        });
    }, [id, onUpdateStatus, status, title]);

    return (
        <>
            <tr key={id} className='border-top'>
                {children}
                {!notAllowEdit && (
                    <td className='text-right'>
                        <span>
                            <Link className='btn btn-outline-success' to={`${navigatePath(navEdit, [id])}`}>
                                <i className='bi bi-pencil-square'></i>
                            </Link>
                            <Button
                                variant={status ? 'outline-danger' : 'outline-warning'}
                                className='ml-3'
                                onClick={clickUpdateStatus}
                            >
                                {status ? (
                                    <i className='bi bi-trash'></i>
                                ) : (
                                    <i className='bi bi-arrow-counterclockwise'></i>
                                )}
                            </Button>
                        </span>
                    </td>
                )}
            </tr>
        </>
    );
};

export default TableRow;
