import UserModel from 'data/model/user_model';
import UserRoleModel from 'data/model/user_role_model';

export type UserDetailFormDataType = {
    title: string;
    data: UserModel;
    files: FileList | null;
    loading: boolean;
};

export type UserDetailActionType = {
    type: UserDetailType;
    payload?: any;
};

export enum UserDetailType {
    loadData = 'LOAD_DATA',
    loadDone = 'LOAD_DONE',
    saveData = 'SAVE_DATA',
    saveDone = 'SAVE_DONE',
    imageChange = 'IMAGE_CHANGE',
    userNameChange = 'USERNAME_CHANGE',
    passwordChange = 'PASSWORD_CHANGE',
    fullNameChange = 'FULLNAME_CHANGE',
    phoneChange = 'PHONE_CHANGE',
    emailChange = 'EMAIL_CHANGE',
    rolesChange = 'ROLES_CHANGE'
}

export const userDetailinitialState: UserDetailFormDataType = {
    title: 'Thêm mới tài khoản admin',
    data: new UserModel(),
    files: null,
    loading: false
};

export const userDetailreducer = (state: UserDetailFormDataType, action: UserDetailActionType) => {
    const resState = { ...state };
    const data = { ...state.data };

    switch (action.type) {
        case UserDetailType.loadData:
            resState.loading = true;
            break;
        case UserDetailType.loadDone:
            resState.loading = false;
            resState.title = action.payload?.title ?? state.title;
            resState.data = (action.payload?.data as UserModel) ?? state.data;
            break;
        case UserDetailType.saveData:
            resState.loading = true;
            break;
        case UserDetailType.saveDone:
            resState.loading = false;
            resState.title = action.payload?.title ?? state.title;
            resState.data = (action.payload?.data as UserModel) ?? state.data;
            break;
        case UserDetailType.imageChange:
            resState.files = (action.payload as FileList) ?? null;
            break;
        case UserDetailType.userNameChange:
            data.userName = (action.payload ?? '').toString();
            resState.data = data;
            break;
        case UserDetailType.passwordChange:
            data.password = (action.payload ?? '').toString();
            resState.data = data;
            break;
        case UserDetailType.fullNameChange:
            data.fullName = (action.payload ?? '').toString();
            resState.data = data;
            break;
        case UserDetailType.phoneChange:
            data.phone = (action.payload ?? '').toString();
            resState.data = data;
            break;
        case UserDetailType.emailChange:
            data.email = (action.payload ?? '').toString();
            resState.data = data;
            break;
        case UserDetailType.rolesChange:
            data.roles = (action.payload ?? []) as UserRoleModel[];
            resState.data = data;
            break;
    }
    return resState;
};
