import { getItemString, LocalStorageKey, setItemString } from '../utils/local_storage/index';
import _ from 'lodash';
import AuthenModel from 'data/model/authen_model';
import { postAPI, APIAuthen } from '../utils/api_service/index';
import UserModel from 'data/model/user_model';
import ResponseData, { ResultCode } from 'data/model/response_data';
import { authenRequest, changePasswordRequest, changeUserInfoRequest } from 'data/requests/authenRequest';
import { ContentType } from 'utils/api_service/api_enum';
import { isExpired } from 'react-jwt';
import { isValidEmail, isValidPassword, isValidPhone } from 'utils/string_ultils';

/**
 * kiểm tra đã đăng nhập hay chưa
 */
export const isAuthened = (): boolean => {
    const value = getItemString(LocalStorageKey.token);
    return !isExpired(value);
};

/**
 * lấy thông tin authen đã đăng nhập
 */
export const getAuthenOld = (): AuthenModel | null => {
    if (isAuthened()) {
        const userInfo = getItemString(LocalStorageKey.userInfo);
        const token = getItemString(LocalStorageKey.token);
        return {
            token: token,
            userDetail: JSON.parse(userInfo)
        };
    }
    return null;
};

/**
 * lấy token - đăng nhập
 */
export const getAuthen = async (data: authenRequest): Promise<ResponseData> => {
    let desc = '';
    if (_.isEmpty(data.userName)) {
        desc = 'Tên đăng nhập không được để trống';
    } else if (_.isEmpty(data.password)) {
        desc = 'Mật khẩu không được để trống';
    }

    if (!_.isEmpty(desc)) {
        return {
            code: ResultCode.dataError,
            mess: 'dữ liệu lỗi',
            desc: desc,
            data: new AuthenModel()
        };
    } else {
        const res = await postAPI(APIAuthen.getToken, data);
        if (res.code === ResultCode.success) {
            const data = res.data as AuthenModel;
            setItemString(LocalStorageKey.token, data.token);
            setItemString(LocalStorageKey.userInfo, JSON.stringify(data.userDetail));
            return res;
        } else {
            return res;
        }
    }
};

/**
 * thay đổi thông tin user đăng nhập
 */
export const changeUserInfo = async (data: changeUserInfoRequest): Promise<ResponseData> => {
    let desc = '';
    if (_.isEmpty(data.userId)) {
        desc = 'Thông tin user không tồn tại';
    } else if (_.isEmpty(data.fullName)) {
        desc = 'Họ tên không được để trống';
    } else if (!isValidPhone(data.phone)) {
        desc = 'Số điện thoại không được để trống';
    } else if (!_.isEmpty(data.email) && !isValidEmail(data.email)) {
        desc = 'Email không được để trống';
    }

    if (!_.isEmpty(desc)) {
        return {
            code: ResultCode.dataError,
            mess: 'dữ liệu lỗi',
            desc: desc,
            data: new AuthenModel()
        };
    } else {
        const res = await postAPI(APIAuthen.changeInfo, data, ContentType.formData);
        if (res.code === ResultCode.success) {
            const data = res.data as AuthenModel;
            setItemString(LocalStorageKey.token, data.token);
            setItemString(LocalStorageKey.userInfo, JSON.stringify(data.userDetail));
            return res;
        } else {
            return res;
        }
    }
};

/**
 * thay đổi mật khẩu
 */
export const changePassword = async (data: changePasswordRequest): Promise<ResponseData> => {
    let desc = '';
    if (_.isEmpty(data.userId)) {
        desc = 'Thông tin user không tồn tại';
    } else if (_.isEmpty(data.passwordOld)) {
        desc = 'Mật khẩu hiện tại không được để trống';
    } else if (!isValidPassword(data.passwordNew)) {
        desc = 'Vui lòng nhập mật khẩu bao gồm ký tự hoa, ký tự thường, số, @$!%*?&';
    } else if (_.isEmpty(data.passwordConfirm)) {
        desc = 'Xác nhận mật khẩu không được để trống';
    } else if (data.passwordNew !== data.passwordConfirm) {
        desc = 'Mật khẩu mới và giá nhận mật khẩu không trùng khớp';
    }

    if (!_.isEmpty(desc)) {
        return {
            code: ResultCode.dataError,
            mess: 'dữ liệu lỗi',
            desc: desc,
            data: new UserModel()
        };
    } else {
        const res = await postAPI(APIAuthen.changePass, data);
        if (res.code === ResultCode.success) {
            return res;
        } else {
            return res;
        }
    }
};

/**
 * đăng xuất
 */
export const logout = () => {
    setItemString(LocalStorageKey.token, '');
    setItemString(LocalStorageKey.userInfo, '');
};
