import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import authenState from '../state/authen_state';
import AuthenModel from 'data/model/authen_model';
import { authenRequest, changePasswordRequest, changeUserInfoRequest } from 'data/requests/authenRequest';

/**
 * state mặc định
 */
const initialState: authenState = {
    loading: false,
    token: '',
    userInfo: undefined
};

/**
 * cài đặt slice authen
 */
const authenSlice = createSlice({
    name: 'authen',
    initialState,
    reducers: {
        authen(state, action: PayloadAction<authenRequest>) {
            state.loading = true;
        },
        authenSuccess(state, action: PayloadAction<AuthenModel>) {
            state.loading = false;
            state.userInfo = action.payload.userDetail;
            state.token = action.payload.token;
        },
        authenFailed(state) {
            state.loading = false;
        },
        reloadAuthen(state) {
            state.loading = true;
        },
        changeUserInfo(state, action: PayloadAction<changeUserInfoRequest>) {
            state.loading = true;
        },
        changeUserInfoSuccess(state, action: PayloadAction<AuthenModel>) {
            state.loading = false;
            state.userInfo = action.payload.userDetail;
            state.token = action.payload.token;
        },
        changeUserInfoFailed(state) {
            state.loading = false;
        },
        changePassword(state, action: PayloadAction<changePasswordRequest>) {
            state.loading = true;
        },
        changePasswordSuccess(state, action: PayloadAction<AuthenModel>) {
            state.loading = false;
        },
        changePasswordFailed(state) {
            state.loading = false;
        },
        logout(state) {
            state.loading = false;
            state.token = '';
            state.userInfo = undefined;
        }
    }
});

// actions
export const authenAction = authenSlice.actions;
// reducer
const authenReducer = authenSlice.reducer;
export default authenReducer;
