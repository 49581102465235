import PagingModel from 'data/model/paging_model';
import { ResultCode } from 'data/model/response_data';
import { getAPI } from 'utils/api_service';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { LogRequest } from 'data/requests/logRequest';
import { APILogger } from 'utils/api_service/api_path';

/**lấy danh sách log */
export const getListLog = async (data: LogRequest): Promise<PagingModel | null> => {
    const res = await getAPI(APILogger.getList, data);
    if (res.code === ResultCode.success) {
        return res.data as PagingModel;
    } else {
        toast.error(_.isEmpty(res.desc) ? res.mess : res.desc);
    }
    return null;
};
