import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { Container } from 'reactstrap';
import { RootState, useAppSelector } from 'presentation/redux/store';

/**
 * layout chung cho toàn ứng dụng
 */
const FullLayout = () => {
    const isAuthened = useAppSelector((state: RootState) => state.authen.userInfo);

    if (isAuthened) {
        return (
            <main>
                <Header></Header>
                <div className='pageWrapper d-lg-flex'>
                    <aside className='sidebarArea shadow' id='sidebarArea'>
                        <Sidebar />
                    </aside>
                    <div className='contentArea'>
                        <Container className='p-4' fluid>
                            <Outlet />
                        </Container>
                    </div>
                </div>
            </main>
        );
    } else {
        return <></>;
    }
};

export default FullLayout;
