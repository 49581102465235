import Loader from 'presentation/layouts/Loader';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FiList, FiPlus, FiSave } from 'react-icons/fi';
import { Card, Row, Col, CardTitle, CardBody, Form } from 'reactstrap';

type propsType = {
    /** tiêu đề*/
    title: string;
    /**tiêu đề của nút */
    titleAction: string;
    /**ẩn hiện spinner */
    loading: boolean;
    /** thẻ con*/
    children: React.ReactNode;
    /** sự kiện sumit form*/
    onSubmit?: () => void;
    /** sự kiện tạo mới */
    onCreate?: () => void;
    /** sự kiện quay về danh sách */
    onGotoList?: () => void;
};

/** layout của form input */
const LayoutForm = ({ title, loading, children, titleAction, onSubmit, onCreate, onGotoList }: propsType) => {
    return (
        <Row>
            {loading && <Loader />}
            <Col>
                <Card>
                    <CardTitle className='border-bottom p-3 mb-0'>
                        <Row className='wrap align-items-center'>
                            <Col xl={8} md={5} xs={12}>
                                <h6>{title}</h6>
                            </Col>
                            <Col className='d-flex mt-lg-0 mt-3 justify-content-end'>
                                {onGotoList && (
                                    <Button
                                        variant='secondary'
                                        className='btn-form-list btn-icon ml-4'
                                        onClick={onGotoList}
                                    >
                                        <FiList size={15} />
                                        <label>Danh sách</label>
                                    </Button>
                                )}
                                {onCreate && (
                                    <Button
                                        variant='primary'
                                        className='btn-form-list btn-icon ml-4'
                                        onClick={onCreate}
                                    >
                                        <FiPlus size={15} />
                                        <label>Thêm mới</label>
                                    </Button>
                                )}
                                {onSubmit && (
                                    <Button
                                        variant='success'
                                        className='btn-form-list btn-icon ml-4'
                                        onClick={onSubmit}
                                    >
                                        <FiSave size={15} />
                                        <label>{titleAction}</label>
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </CardTitle>

                    <CardBody>
                        <Form>{children}</Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default LayoutForm;
