import { useCallback } from 'react';
import userDefault from '../../assets/images/default/user_default.png';

type props = {
    src?: string;
    alt?: string;
    className?: string;
    size?: number | string;
    style?: React.CSSProperties;
};

/**
 * vata mặc định của user
 */
const AvataUser = ({ src, style, alt, className, size }: props) => {
    /**
     * load hình mặc định khi không load được hình từ src
     */
    const handleError = useCallback((event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.onerror = null; // Ngăn chặn lặp vô hạn nếu hình ảnh mặc định cũng không tải được
        event.currentTarget.src = userDefault;
    }, []);

    return (
        <img
            src={src}
            style={{ ...style }}
            className={className}
            alt={alt ?? ''}
            width={size}
            height={size}
            onError={handleError}
        />
    );
};

export default AvataUser;
