import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga_middlewares';
import authenReducer from '../slices/authen_slice';

/**
 * tạo middleware saga
 */
const sagaMiddleware = createSagaMiddleware();

/**
 * nơi chứa tất cả reducer
 */
const rootReducer = combineReducers({
    authen: authenReducer
});

/**
 * lưu trữ state cho toàn ứng dụng
 */
export const store = configureStore({
    reducer: rootReducer,
    devTools: true, // có sử dụng devtool trên trình duyệt hay không
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true, // có sử dụng middleware thunk hay không
            serializableCheck: true, // có sử dụng middleware serializableCheck hay không
            immutableCheck: true // có sử dụng middleware immutableCheck hay không
        }).concat(sagaMiddleware)
});

// khởi chạy saga
sagaMiddleware.run(rootSaga);

export default store;
