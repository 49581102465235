import React, { useCallback } from 'react';
import {
    Navbar,
    Collapse,
    Nav,
    NavbarBrand,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Button
} from 'reactstrap';
import Logo from './Logo';
import { ReactComponent as LogoWhite } from '../../assets/images/logos/materialprowhite.svg';
import { RootState, useAppAppDispatch, useAppSelector } from 'presentation/redux/store';
import AvataUser from 'presentation/component/AvataUser';
import { authenAction } from 'presentation/redux/slices/authen_slice';
import appNavigate from 'presentation/routers/app_navigate';
import RouterPath from 'presentation/routers/router_path';

const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const userInfo = useAppSelector((state: RootState) => state.authen.userInfo);
    const dispatch = useAppAppDispatch();

    const toggle = useCallback(() => setDropdownOpen((prevState) => !prevState), []);
    const Handletoggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    const showMobilemenu = useCallback(() => {
        (document.getElementById('sidebarArea') as HTMLElement).classList.toggle('showSidebar');
    }, []);
    const handleLogout = useCallback(() => dispatch(authenAction.logout()), [dispatch]);
    const handleInfoUser = useCallback(() => appNavigate.navigator!(RouterPath.changeInfo), []);
    const handlePass = useCallback(() => appNavigate.navigator!(RouterPath.changePass), []);

    return (
        <Navbar color='primary' dark expand='md' className='fix-header'>
            <div className='d-flex align-items-center'>
                <div className='d-lg-block d-none me-5 pe-3'>
                    <Logo />
                </div>
                <NavbarBrand href='/'>
                    <LogoWhite className=' d-lg-none' />
                </NavbarBrand>
                <Button color='primary' className=' d-lg-none' onClick={() => showMobilemenu()}>
                    <i className='bi bi-list'></i>
                </Button>
            </div>
            <div className='hstack gap-2'>
                <Button color='primary' size='sm' className='d-sm-block d-md-none' onClick={Handletoggle}>
                    {isOpen ? <i className='bi bi-x'></i> : <i className='bi bi-three-dots-vertical'></i>}
                </Button>
            </div>

            <Collapse navbar isOpen={isOpen}>
                <Nav className='me-auto' navbar></Nav>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color='transparent'>
                        <AvataUser src={userInfo?.image} alt='profile' className='rounded-circle' size='30'></AvataUser>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Thông tin</DropdownItem>
                        <DropdownItem onClick={handleInfoUser}>Thông tin tài khoản</DropdownItem>
                        <DropdownItem onClick={handlePass}>Đổi mật khẩu</DropdownItem>
                        <DropdownItem onClick={handleLogout}>Đăng xuất</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Collapse>
        </Navbar>
    );
};

export default Header;
