/**
 * domain của api
 */
const domain = 'https://api.boxsolution.vn/api/admin/';
//const domain = 'https://localhost:7170/api/admin/';

/**
 * các api authen
 */
export enum APIAuthen {
    /**
     * lấy token - đăng nhập
     */
    getToken = `${domain}authen/get-token`,
    /**
     * thay đổi mật khẩu của user đang đăng nhập
     */
    changePass = `${domain}authen/change-pass`,
    /**
     * thay đổi thông tin user đang đăng nhập
     */
    changeInfo = `${domain}authen/change-info`
}

/**
 * các api quản lý thông tin user
 */
export enum APIUserManagement {
    /**
     * lấy danh sách
     */
    getList = `${domain}user/list`,
    /**
     * lấy thông tin chi tiết
     */
    getDetail = `${domain}user/get-detail`,
    /**
     * lấy mẫu data
     */
    getDataEmpty = `${domain}user/get-data-empty`,
    /**
     * tạo mới 1 dữ liệu
     */
    createNew = `${domain}user/create-new`,
    /**
     * cập nhật 1 dữ liệu
     */
    update = `${domain}user/update`,
    /**
     * thay đổi trạng thái 1 dữ liệu
     */
    changeStatus = `${domain}user/change-status`
}

/**
 * các api logger
 */
export enum APILogger {
    /**
     * lấy danh sách
     */
    getList = `${domain}log/list`
}
