import { FloatLabel } from 'primereact/floatlabel';
import { Password } from 'primereact/password';
import { ChangeEventHandler } from 'react';
import _ from 'lodash';
import { isValidPassword } from 'utils/string_ultils';

type props = {
    /**tiêu đề - label */
    title: string;
    /**giá trị */
    value: string;
    /**có bắt buộc nhập hay không */
    required?: boolean;
    /**sự kiện khi giá trị thay đổi */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** ẩn hay hiện input */
    hide?: boolean;
    /**có hiển thị ô gợi ý nhập hay không */
    feedback?: boolean;
    /**có vô hiệu ô nhập - không cho nhập hay không */
    disabled?: boolean;
    /**có bỏ qua validate mật khẩu hay không */
    notValidate?: boolean;
};

/**input password */
const PasswordControl = ({ title, value, required, onChange, hide, feedback, disabled, notValidate }: props) => {
    if (!hide) {
        const invalid = (): boolean => {
            if (required && _.isEmpty(value)) {
                return true;
            } else if (!_.isEmpty(value) && !notValidate) {
                return !isValidPassword(value);
            }

            return false;
        };

        return (
            <div className='input-field-mt'>
                <FloatLabel>
                    <Password
                        value={value}
                        className={`pasword-field ${invalid() ? 'p-invalid' : ''}`}
                        inputClassName='form-control'
                        onChange={onChange}
                        required={required}
                        invalid={invalid()}
                        disabled={disabled}
                        toggleMask
                        feedback={feedback}
                        promptLabel='Nhập mật khẩu bao gồm ký tự hoa, ký tự thường, số, @$!%*?&'
                        weakLabel='Yếu'
                        mediumLabel='Trung bình'
                        strongLabel='Mạnh'
                        strongRegex='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$'
                    />
                    <label>{`${title} ${required ? '*' : ''}`}</label>
                </FloatLabel>
            </div>
        );
    }
    return <></>;
};

export default PasswordControl;
