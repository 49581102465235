import UserRoleModel from './user_role_model';

/**
 * chi tiết thông tin user
 */
export default class UserModel {
    /**
     * mã
     */
    id: string;
    /**
     * tên đăng nhập
     */
    userName: string;
    /**
     * mật khẩu
     */
    password: string;
    /**
     * họ và tên
     */
    fullName: string;
    /**
     * số điện thoại
     */
    phone: string;
    /**
     * thư điện tử
     */
    email: string;
    /**
     * url ảnh đại diện
     */
    image: string;
    /**
     * đăng ký từ kênh nào
     */
    registerFrom: number;
    /**
     * ngày tạo định dạng dd-MM-yyyy HH:mm:ss
     */
    sCreateDate: string;
    /**
     * đang active hay không
     */
    status: boolean;
    /**
     * danh sách phân quyền
     */
    roles: UserRoleModel[];

    constructor() {
        this.id = '';
        this.userName = '';
        this.password = '';
        this.fullName = '';
        this.phone = '';
        this.email = '';
        this.image = '';
        this.registerFrom = 0;
        this.sCreateDate = '';
        this.status = false;
        this.roles = [];
    }
}
