import LocalStorageKey from './local_storage_key';
import _ from 'lodash';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

const _secretKey = 'eyJuYW1lMSI6IlRy4bqnbiBUcuG7jW5nIFTDoGkiLCJuYW1lMiI6IlBow7pjIHbGsMahbmciL';

/**
 * Lưu dữ liệu vào local storage
 * @param key - key dùng để quản lý.
 * @param value - giá trị cần lưu.
 */
export const setItemString = (key: LocalStorageKey, value: string): boolean => {
    try {
        let valueSave = '';
        if (!_.isEmpty(value)) {
            valueSave = aes.encrypt(value, _secretKey).toString();
        }
        localStorage.setItem(key, valueSave);
        return true;
    } catch (ex) {
        return false;
    }
};

/**
 * Lấy giá trị từ local storage
 * @param key - key dùng để quản lý.
 */
export const getItemString = (key: LocalStorageKey): string => {
    try {
        let value = localStorage.getItem(key);
        if (!_.isEmpty(value)) {
            const bytes = aes.decrypt(value ?? '', _secretKey);
            const text = bytes.toString(enc);
            return text;
        }
    } catch (ex) {}
    return '';
};
